import {API_Endpoints} from "../constants";
import {getAPI, getAPIV2, postAPI} from "./http.service";

export const ReconciliationDetailsService = {

    getReconciliationSummary(reconciliationId: number){
        const url = API_Endpoints().getBaseUrl+'reconciliations/'+reconciliationId+'/metadata?card=matched&card=reconciled&card=reasonCodes';
        return getAPI(url);
    },
    getReconciliationById(reconciliationId: number){
        const url = API_Endpoints().reconciliationBaseURL+reconciliationId;
        return getAPI(url);
    },
    getReconciliationNotification(reconciliationId: number){
        const url = API_Endpoints().getNotificationsURL+reconciliationId;
        return getAPI(url);
    },
    async getRecociliationmappableFields(reconciliationId: number){
        if(!reconciliationId) return;
        const url = API_Endpoints().reconciliationBaseURL+reconciliationId+'/mappableFields';
        return getAPI(url);
    },
    getReconciliationRetailerData(reconciliationId: number, selectedRow : number | null, top: number = 10, skip: number = 0, type?: "unreconciled" | "reconciled", fieldFilter: any = {}){
        let url = API_Endpoints().reconciliationBaseURL+reconciliationId+'/records/'+ type + '/source';
        // if(selectedRow){
            fieldFilter['matchIdFilter'] = selectedRow;
            // url = API_Endpoints().reconciliationBaseURL + reconciliationId + '/matches/' + selectedRow + '/source';
        // }
        return getAPI(url, {top, skip, ...fieldFilter});
    },
    getReconciliationVendorData(reconciliationId: number, selectedRow: number | null, recordId?: string , top: number = 10, skip: number = 0, type?: "unreconciled" | "reconciled", fieldFilter: any = {}){
        let url = API_Endpoints().reconciliationBaseURL+reconciliationId+ '/records/'+ type + '/target';
        // if(selectedRow){
            fieldFilter['matchIdFilter'] = selectedRow;
            // url = API_Endpoints().reconciliationBaseURL + reconciliationId + '/matches/' + selectedRow + '/target';
        // } 
        // else if(type === 'reconciled'){
        //     url = API_Endpoints().reconciliationBaseURL+reconciliationId + '/matches/'+ recordId + '/target';
        // }
        return getAPI(url, {top, skip, ...fieldFilter});
    },
    exportReconciliationData(reconciliationId: number, format: 'VendorData' | 'RetailerData' | 'VendorAndRetailerData'){
        const url = API_Endpoints().getBaseUrl+'reconciliations/'+reconciliationId+'/export';
        return getAPIV2(url, "blob", {format:format});
    },
    updateReconciliationRetailerData(reconciliationId: number, payload: any){
        const url = API_Endpoints().getBaseUrl+reconciliationId;
        return postAPI(url, payload);
    },
    reconcile(reconciliationId: number, payload: any, type: string){
        const url = API_Endpoints().getBaseUrl+'reconciliations/'+reconciliationId+'/records/'+type;
        return postAPI(url, payload);
    },
    updateRetailerData(reconciliationId: number, type: number, payload: any){
        const url = API_Endpoints().getBaseUrl + 'reconciliations/' + reconciliationId + '/records/' + (type == 1 ? 'target' : 'source') + '/update';
        return postAPI(url, payload);
    },
    updateReconciliationStatus(reconciliationId: number, status: string){
        const url = API_Endpoints().getBaseUrl+'reconciliations/'+reconciliationId+'/shift';
        return postAPI(url, {status});
    }
}