import { Reducer } from "react";
import { AuthAction, AuthState } from "./types";

const initialState = { isLoggedIn: false, referrer: null };

const reducer: Reducer<AuthState, AuthAction> = (prev, action) => {
  switch (action.type) {
    case "set_referrer":
      return { ...prev, referrer: action.referrer };
    case "login":
      return { ...prev, isLoggedIn: true };
    case "set_me":
      // @ts-ignore
      return {
        ...prev,
        me: {
         // companyId: action.session.companyId,
        //  companyName: action.session.companyName,
          FirstName: action.me.FirstName,
          LastName: action.me.LastName,
          Id: action.me.Id,
          UserName:action.me.UserName,
          Email:action.me.Email,
          ParentEntityId: action.me.ParentEntityId,
          Error: action.me.Error
        },
      };
    case "set_parententity":
      return {
        ...prev,
        parentEntity: {
           Id: action.parent.Id,
           Name: action.parent.Name,
           Role: action.parent.Role,
           Error: action.parent.Error
        },
      };
      case "set_permissioninfo":
        return {
          ...prev,
          permissionInfo: {
             Permissions: action.permissionInfo.Permissions,
             Error: action.permissionInfo.Error
          },
        };  
    case "logout":
      return initialState;
  }
};

export { reducer as authReducer, initialState };
