import {IQMDropdown} from "@iqm-ui-library/booster/antd/IQMDropdown";
import {IQMButton, IQMTable} from "@iqm-ui-library/booster";
import React, {startTransition, useEffect,  useState} from "react";
import {ReconciliationService} from "../../services/reconciliation.service";
import { Col, DatePicker, Flex, Form, Modal} from 'antd';
import {GetColumns} from "./allReconciTableConstant";
import { ReconciliationsPagedResponse, Records} from "./allReconciliation.interface";
import {PaginationComponent} from "../pagination/pagination"
import './allReconciliation.css'
import {dateFormat} from "../utils/commonFunctions";
import { IReconciliationStatus } from "../reconciliationDetails/reconciliationDetailsPage.interface";
import { CloseCircleFilled } from "@ant-design/icons";
import { useLogout } from "../../auth";
import { useNavigate } from "react-router-dom";

export enum dateEnum {
    All = "All",
    Yesterday = "Yesterday",
    Last7days = "Last 7 days",
    Last15days = "Last 15 days",
    Last30days = "Last 30 days",
    Custom = "Custom"
}

export function AllReconciliations(props: any) {
    const [columnData, setColumnData] = useState(GetColumns(onFilter, resetData, onSearch, handleReset));
    function handleReset(clearFilters: () => void) {
        clearFilters();
        setFilterString(undefined);
        setFilterStatus([]);
    };

    function resetData() {
        setDataSource([]);
        setConsumedData(0);
        setItemCount(0);
        setPagination({top: 10,current: 1})
    }

    async function viewReason ({id}: {id: string}) {
        if(!id) return;
        const val = await reconciliationService.getReconciliationStatus(id);
        setModalProps({title: "Failed reason!", message: val?.message});
        setDisplayModal(true);
    }

    function onSearch(selectedKeys: React.Key[]) {
        setFilterString((selectedKeys[0])?.toString());
    }

    function onFilter(value: any, record: any): boolean {
        return true;
    }

    let today:any
    today = new Date();
     /* used for dropdown default to last7days */
    // const last7DayDate = new Date(today)
    const reconciliationService = new ReconciliationService();
    const { RangePicker } = DatePicker;

    const [dataSource, setDataSource] = useState<ReconciliationsPagedResponse['listOfReconciliation']>([]);
    const [loading, setLoading] = useState(false);
    const [displayModal, setDisplayModal] = useState(false);
    const [filterDateOptions, setfilterDateOptions] = useState<any>([]);
    const [selectedFilterOpt, setSelectedFilterOpt] = useState()
    const [isOpen, setOpen] = useState(false)
    const [periodRangeEndUtc, setPeriodRangeEndUtc]= useState<any>(undefined)
    /* used for dropdown default to last7days */
    // const last7Days = new Date(last7DayDate.setDate(last7DayDate.getDate() - 7))
    const [periodRangeStartUtc, setPeriodRangeStartUtc]= useState(undefined)
    const [filterString, setFilterString]=useState<string>()
    const [statusFilterString, setFilterStatus]=useState<string[]>()
    const [itemCount, setItemCount] = useState(0)
    const [pagination, setPagination] = useState({top:10, current:1})
    let [consumedData, setConsumedData] = useState(0)
    const [pageSizeCount, setPageSizeCount] = useState(5)
    const [modalProps, setModalProps] = useState<any>({title: "", message: ''});
    const [isAPICallVal, setIsAPICall] = useState(0)
    const [prevPage, setPrevPage] = useState(1) ;
    let fetchNewItems :number;
    let startDate :any;
    let endDate : any;
    let userInfo :any[] =[];
    const navigate = useNavigate();

    const logout: any = useLogout();
    
    /* Fetch All Reconciliation API */
    async function getAllReconciliation(params?:any) {
        let itemsPerAPICall = 100;
        if (params.top > itemsPerAPICall) {
            let numberOfApis = Math.ceil(params.top/itemsPerAPICall);
            // let lastTop = params.top%100;
            let top = itemsPerAPICall;
            let skip = params.skip;
            let lastPage = (pagination.current == Math.ceil(itemCount/pageSizeCount));
            while(lastPage ? (numberOfApis>0) : (numberOfApis>=0)){
                // if(numberOfApis===1){
                //     top = lastTop;
                //   }
                  numberOfApis--
              await getAllReconciliation({
                top: top,
                skip: skip,
                nameFilter: params.nameFilter || null,
                status: params.statusFilterString
              });
              skip = skip+itemsPerAPICall;
            }
          }
          else{
            const cond = itemCount ? (dataSource?.length < (itemCount || 0)) : true;
            if(params.top > 0 && cond){
            // let topByCurrentPage = pagination.top/pagination.current
            //   if( ( ( pagination.current >= prevPage ) && (dataSource.length !== +itemCount) && (topByCurrentPage < pageSizeCount)) || (pagination.current === 1  && dataSource.length === 0)){
                const [val, err] = await reconciliationService.getReconciliation(params);
                if(err && err.message == 'Request failed with status code 401'){
                    logout();
                }
                setIsAPICall((isAPICallVal || 0) + 1)
                setItemCount(Number(val?.metadata.totalCount) || 0);
                setOpen(false)
                const updatedRecords = val?.records
                if(updatedRecords){
                    /*To get username */
                    let tempParams = ''
                    let tempIds:any = []
                    updatedRecords?.map((item:any)=>{
                        item.customStatus = item.status === IReconciliationStatus.ReadyToReview ? 'Ready to review' : item.status
                        if( (item?.lastUpdatedById) && !tempIds.includes(item?.lastUpdatedById)){
                            tempIds.push(item?.lastUpdatedById)
                        }
                    });
                    tempIds.map((lastUpdatedById:any)=>{
                        tempParams = tempParams  ? tempParams+'&userId='+lastUpdatedById : '?userId='+lastUpdatedById
                    })
                    if(tempParams){
                        userInfo  = await reconciliationService.getUserInfo(tempParams)
                    }
                    let updatedUserRecord : any
                    updatedUserRecord = updatedRecords?.map((it: any, index: number) => ({
                        ...it,
                        key: it?.id, //Id to create key
                        ...getUsers(it?.lastUpdatedById, userInfo)
                    }));
                    setDataSource((prevState)=> ([...prevState, ...updatedUserRecord]));
                }
            }
          }
    }

    useEffect(() => {
        /*check for last page and fetch */
        if ((itemCount > 0) && dataSource.length >= +itemCount) return
        // if (Math.ceil(itemCount / pageSizeCount) === pagination.current) {
        //     fetchNewItems = itemCount - consumedData
        // } else {
        //     fetchNewItems = Math.abs(Number(pageSizeCount * (pagination.current - prevPage)));
        // }
        const dataFetchCount = (pagination.current * pageSizeCount) - dataSource.length;
        if (!isOpen) {
            getAllReconciliation({ periodRangeStartUtc, periodRangeEndUtc, top: dataFetchCount, skip: consumedData, nameFilter: filterString || null, status: statusFilterString });
            // getAllReconciliation({ periodRangeStartUtc, periodRangeEndUtc, top: fetchNewItems ? fetchNewItems : pagination.top, skip: consumedData, nameFilter: filterString || null, status: statusFilterString });
        }
    }, [pagination.current, periodRangeStartUtc, (!isOpen), ((itemCount > 0) && (dataSource.length >= +itemCount)), filterString, statusFilterString, pageSizeCount]);

    /* Onclick on seeDetails trigger handleMenu of home page to render details page*/
    const seeDetails = (e: any, item: any) => {
        // props.parentHandler(e, item)
        startTransition(() => {
            navigate(`/reconciliations/${item.id}`);
        })
    }

    /* Set onClick method in dataSource on load */
    useEffect(() => {
        const hideSeeDetails = [IReconciliationStatus.Failed, IReconciliationStatus.Processing];
        dataSource.forEach((item: Records) => {
            item.onClick = item.status === IReconciliationStatus.Failed  ? viewReason.bind(item) : seeDetails.bind(item, 'RD')
            item.action = hideSeeDetails.includes(item.status as IReconciliationStatus) ? IReconciliationStatus.Failed === item.status ? "View reason" : "" : "See details";
            item.reconciliationPeriod = `${dateFormat(item?.periodStartDateUtc)} - ${dateFormat(item?.periodEndDateUtc)}`
            item.unmatchedItemCountUI = item.unmatchedItemCountUI ? item.unmatchedItemCountUI : `${item.unmatchedItemCount}/${item.totalItemCount} `;
            item.reconciledItemCountUI = item.reconciledItemCountUI? item.reconciledItemCountUI : `${item.reconciledItemCount}/${item.totalItemCount} `
        })
        setDataSource(dataSource?dataSource:[])
        const filterDateOptions = (Object.entries(dateEnum).map(val => {
            return {
                label: val[1],
                value: val[1]
            }
        }));
        setfilterDateOptions(filterDateOptions)
        setConsumedData(dataSource.length)
    }, [dataSource]);

    function getUsers (id:number, userInfo:any) {
        const user = userInfo?.users?.find((it :any) => it?.id == id)
        return {
            lastUpdatedByName : (user?.firstName || '')+" "+(user?.lastName || '')
        }
    }

    /*Format date in MM/DD/YYYY (UTC)*/
    function formatDate(tempDate:any){
        return tempDate && dateFormat(tempDate);
    }

    /*clear state when filter selected*/
    function clearState(){
        setDataSource([]);
        setConsumedData(0)
        setIsAPICall(0)
        setPrevPage(1)
        setPagination((prevState) => ({...prevState, ...{current: 1}}))
        resetData();
    }

    /* Set start and end date period and pass these params to reconcile API call */
    function setStartAndEndPeriod(value:any){
        /*Set Start date */
        if(value === dateEnum.Custom) {
            setOpen(true)
        } else {
            setOpen(false);
            /*Set End date as of today's*/
            setPeriodRangeEndUtc(formatDate(today))

            /*Set Start date */
            if(value === dateEnum.Yesterday){
                today.setDate(today.getDate() - 1);
                setPeriodRangeStartUtc(formatDate(today))
            }else if(value === dateEnum.Last7days){
                today.setDate(today.getDate() - 7);
                setPeriodRangeStartUtc(formatDate(today))
            }else if(value === dateEnum.Last15days){
                today.setDate(today.getDate() - 15);
                setPeriodRangeStartUtc(formatDate(today))
            }else if(value === dateEnum.Last30days){
                today.setDate(today.getDate() - 30);
                setPeriodRangeStartUtc(formatDate(today))
            } else if(value === dateEnum.All || value === undefined){
                setPeriodRangeStartUtc(undefined)
                setPeriodRangeEndUtc(undefined)
            }
            clearState();
        }
    }

    /*On change filter and display items */
    const handlePeriodChange = (value: any) => {
        /*enum use state set value*/
        setSelectedFilterOpt(value)
        /*set start end period range */
        setStartAndEndPeriod(value);
    };

    /*On selecting date picker set date range */
    function onCalendarSelection(dates: any, dateStrings: any[], info: any){
        startDate = dateStrings[0];
        endDate = dateStrings[1];
        if(startDate && endDate) {
            clearState();
            setPeriodRangeStartUtc(startDate);
            setPeriodRangeEndUtc(endDate)
            setOpen(true)
        }
    }

    /*On page change*/
    function onTableChange(paginationFromComp:any, filters?:any, sort?:any){
        setPrevPage(pagination.current)
        if(pageSizeCount!==paginationFromComp.newPageSize){
          setPageSizeCount(paginationFromComp.newPageSize);
          setPagination((previousValues) => ({ ...previousValues, ...{ current: 1 } }))
        }else{
          setPagination((previousValues) => ({ ...previousValues, ...{ current: paginationFromComp.page } }))
        }
      }

    const onChange = (pagination: any, filters?: any, sort?: any) => {
        const obj = filters?.customStatus?.map((item: any) => item);
        setFilterStatus(obj || {});
        resetData();
    }

    return <>
        <Form initialValues={{ periodSelect: dateEnum.All}}>
            <Flex wrap gap="small">
                <Col>
                <IQMDropdown
                    required={false}
                    value={selectedFilterOpt}
                    onChange={(e: any) => handlePeriodChange(e)}
                    name="periodSelect"
                    options={filterDateOptions}
                    showSearch={false}
                    width={320}
                    placeholder="Select a Period"
                    allowClear={true}
                /></Col>
                <Col>{ selectedFilterOpt === dateEnum.Custom &&
                    <RangePicker autoFocus={true} allowClear={true} disabledDate={d => !d
                        || d.isAfter(today)}  onOpenChange={setOpen} style={{marginLeft:'20px'}} onCalendarChange={onCalendarSelection} format={'MM/DD/YYYY'}/>
                }</Col>
            </Flex>
        </Form>

        <Modal open={displayModal} closable={true}
            width={'40vw'} okText={"Save"} title={
                modalProps?.message && <>
                    <CloseCircleFilled style={{ color: 'red', marginRight: '5px' }} />
                    {modalProps.title}
                </>
            }
            footer={null} centered={true} onCancel={() => setDisplayModal(false)}>
            
            {!modalProps?.message ? 'An unexpected service error occurred' : <div style={{
                    overflow: "auto",
                    maxHeight: "250px"
                }
            } dangerouslySetInnerHTML={{
                __html: modalProps.message?.replace(/ /g, '&nbsp;')?.replace(/\n/g, '<br />')?.replace(/\r/g, '<br />'),
            }}></div>}

            <Flex justify={"flex-end"} gap={16}>
                <IQMButton label={"Ok"} primary={true} htmlType={"submit"} onClick={() => setDisplayModal(false)}></IQMButton>
            </Flex>
        </Modal>

        <IQMTable
            dataSource={dataSource?dataSource:null}
            expandable={true}
            columns={columnData}
            pageSize={pageSizeCount}
            current={pagination.current}
            total={itemCount}
            tableHeader={""}
            handleChange={onChange}
            dropdown={false}
            loading={loading}
            scroll={{x:'scroll'}}
            footer={() =><>
                <PaginationComponent current={pagination.current} total={itemCount} pageSize={pageSizeCount} onChange={onTableChange} disableLastPage={true} showQuickJumper={false} showSizeChanger={true} pageSizeOptions={[5,10]}/></>
            }
        />
    </>;
}
