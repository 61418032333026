import { useContext, useEffect, useState } from "react";
import { INotification, IQMHeader } from "@iqm-ui-library/booster";
import { useNavigate } from "react-router-dom";

import { useCompany, useLogout, useSession } from "../../auth";
import { NotificationContext } from "../utils/notificationContext";
import { ReconciliationDetailsService } from "../../services/reconciliationDetails.service";
import { burgermenuItems } from "../../router";

interface IHeaderProps {
    handleMenu: (selectedItem: string) => void;
    reconciliationId?: number,
    selectedKey?: string
}

export const CustomHeader = (props: IHeaderProps) => {
    const [newpushNotification, setNewPushNotification] = useState<any>()
    const [notifications, setNotifications] = useState<Array<any>>([])
    const [reconciliationIds, setReconciliationIds] = useState<Array<number>>([])
    const notificationContext = useContext(NotificationContext);

    const company: any = useCompany();
    const navigate = useNavigate();
    const companyName = company?.parent?.company?.name;
    const session: any = useSession();
    const logout: any = useLogout();
    let timerId: NodeJS.Timeout;

    function menuItemClicked(val: any) {
        if (val.key.indexOf('Sign Out') > -1) {
            logout()
        } else if (val.key.indexOf('Notification') > -1) {
            if (val.id) {
                setNotifications(prev => prev?.filter((notify) => (notify.id != val.id)))
                setReconciliationIds(prev => prev?.filter(id => (id != val.id)));
                ReconciliationDetailsService.getReconciliationNotification(val.id).then((res: any) => {
                    const data = res.data;
                    data.reconciliationPeriod = `${new Date(data.periodStartDateUtc).toLocaleDateString()} - ${new Date(data.periodEndDateUtc).toLocaleDateString()}`
                    navigate(`/reconciliations/${val.id}`);
                })
            }
        }
    }

    useEffect(() => {
        const sessionNotification = sessionStorage.getItem('notification');
        sessionNotification && setNotifications(JSON.parse(sessionNotification));

        const tempReconciliationIds = sessionStorage.getItem('reconciliationIds');
        const parsedIds = tempReconciliationIds && JSON.parse(tempReconciliationIds);
        parsedIds?.length && parsedIds.forEach((val: number) => {
            setReconciliationIds((prev: any) => (prev.indexOf(val) == -1) ? [...prev, val] : [...prev])
        });
        return () => clearTimeout(timerId);
    }, [])

    useEffect(() => {
        if (props.reconciliationId) {
            setReconciliationIds((prev: any) => (prev.indexOf(props.reconciliationId) == -1) ? [...prev, props.reconciliationId] : [...prev])
        }
    }, [props.reconciliationId]);

    /** Enable when the API is provided by the BE team */
    useEffect(() => {
        const tempReconciliationIds = sessionStorage.getItem('reconciliationIds');
        const parsedIds = tempReconciliationIds && JSON.parse(tempReconciliationIds);

        reconciliationIds && reconciliationIds?.forEach((id) => {
            if (!parsedIds?.includes(id)) {
                onLoadPage(id)
            }
        })

        sessionStorage.setItem('reconciliationIds', JSON.stringify(reconciliationIds))
    }, [reconciliationIds])

    useEffect(() => {
        sessionStorage.setItem('notification', JSON.stringify(notifications))
    }, [notifications])

    useEffect(() => {
        const foundNotify = notifications.find(notific => (notific.id === newpushNotification.id));
        !foundNotify && notificationContext.setNotification({ ...newpushNotification })
    }, [newpushNotification])

    async function onLoadPage(reconciliationId: number) {
        const reconcileStatus = ['Ready'];
        let timesRun = 0;
        const intervalId = setInterval(() => {
            timesRun += 1;
            if (timesRun === 10) {
                clearInterval(intervalId);
                setReconciliationIds([]);
            }

            ReconciliationDetailsService.getReconciliationNotification(reconciliationId)
                .then((val) => {
                    if (val && reconcileStatus.includes(val.data.status)) {
                        const status = val?.data.status?.toLowerCase();
                        const obj: INotification = {
                            id: reconciliationId,
                            type: 'info',
                            placement: 'topRight',
                            message: `Reconciliation file is ${status}.`,
                            description: `${val.data.name} is ${status} to reconcile.`
                        }
                        setNewPushNotification(obj);

                        timerId = setTimeout(() => {
                            setNotifications((prev: Array<any>) => {
                                const foundNotify = prev.find(notific => (+notific.id === Number(obj.id)));
                                if (!foundNotify) {
                                    return [...prev, obj]
                                }
                                return [...prev]
                            });
                            clearInterval(intervalId)
                        }, 0);
                    }
                })
        }, 20000)
    }

    return <IQMHeader left={
        {
            items: burgermenuItems,
            onClick: props.handleMenu,
            selectedKeys: props.selectedKey ? [props.selectedKey] : []
        }
    }
        center={{ title: 'ReconcilePro' }}
        right={{ user: session?.session?.user, notifications: notifications, isDrawer: false, companyName: companyName, onClick: (e: any) => menuItemClicked(e) }}
    />
}
