import { AxiosError, AxiosResponse } from "axios";
import { API_Endpoints } from "../constants";
import { ActionKeysEnum, DataSourceFieldEnum, DataSourceKeysEnum, IRule, IRuleAction, IRuleCondition, IRuleSet, IRuleSetReq } from "../constants/rule.interface";
import { getAPI, deleteAPI, postAPI } from "./http.service";
import { useContext } from "react";
import { LoaderContext } from "../components/utils/loaderContext";
import { NotificationContext } from "../components/utils/notificationContext";

const endpoints = API_Endpoints();

export class RuleSetService {
    loaderContext = useContext(LoaderContext);
    private notificationContext = useContext(NotificationContext);

    getRuleSetLinkedToTemplate = async (templateId: string) => {
        this.loaderContext.setLoader(true);
        return await getAPI(endpoints.getRuleSetLinkedToTemplate+`/${templateId}/rulesets`)
            .then((res: { data: any; }) => {
                this.loaderContext.setLoader(false);
                return res.data;
            }).catch(err => {
                this.loaderContext.setLoader(false);
                this.notificationContext.setNotification({ ...err })
            });
    }

    getRuleSetSummary = async (params?: any) => {
        this.loaderContext.setLoader(true);
        return await getAPI(endpoints.getRuleSetSummaryURL, params)
            .then((res: { data: any; }) => {
                this.loaderContext.setLoader(false);
                return res.data;
            }).catch(err => {
                this.loaderContext.setLoader(false);
                this.notificationContext.setNotification({ ...err })
            });
    }

    constructRulsetPayload = (data: IRuleSet) => {
        function constructConditionsPayload(rule: IRule) {
            return rule.conditions.map(condition => {
                const isValue = condition?.operator?.toLowerCase()?.indexOf('value');
                const tempField = (isValue && (isValue > -1)) ? 'field' : 'leftField';
                let conditionObj: IRuleCondition = {
                    operator: condition.operator,
                    [tempField]: {
                        origin: condition[DataSourceFieldEnum.retailerDataSource] ? (condition[DataSourceFieldEnum.retailerDataSource] === DataSourceKeysEnum.RetailerDataKey ? "Source" : "Target") : condition[tempField]?.origin === 'Source' ? "Source" : "Target",
                        id: condition.sourceFieldId || condition[tempField]?.id,
                    }
                }

                if (isValue && (isValue > -1)) {
                    const tempVal = condition[DataSourceFieldEnum.dataSourceValue];
                    conditionObj[DataSourceFieldEnum.dataSourceValue] = tempVal ? String(tempVal) : "";
                } else {
                    conditionObj.rightField = {};
                    conditionObj.rightField['origin'] = condition[DataSourceFieldEnum.templateDataSource] ? (condition[DataSourceFieldEnum.templateDataSource] !== DataSourceKeysEnum.RetailerDataKey ? "Target" : "Source") : condition.rightField?.origin === 'Target' ? "Target" : "Source";
                    conditionObj.rightField['id'] = condition.targetFieldId || condition?.rightField?.id;
                }
                return conditionObj;
            });
        }

        function constructActionsPayload(actions: IRuleAction[] | undefined) {
            return actions?.map((action: IRuleAction) => {
                if (action.actionType === ActionKeysEnum.Reconcile) {
                    delete action.reasonCode;
                }
                return action;
            })
        }

        function constructRulesPayload() {
            return data?.rules?.map((rule, index) => {
                const tempRule: IRule = {
                    id: rule.id,
                    name: rule.name,
                    cardinality: rule.cardinality ? 'OneToOne' : 'ManyToMany',
                    conditions: constructConditionsPayload(rule),
                    actions: constructActionsPayload(rule.actions)
                }
                return tempRule;
            })
        }

        const payload: IRuleSetReq = {
            id: data.id,
            version: data.version,
            name: data.name,
            template: {
                id: data.template?.id,
                version: data?.template?.version
            },
            rules: constructRulesPayload()
        };
        return payload;
    }

    submitRuleSet = async (data: IRuleSet) => {
        const reqBody: IRuleSetReq = this.constructRulsetPayload(data);
        this.loaderContext.setLoader(true);

        if (!data.id) {
            return await postAPI(endpoints.createRuleSetURL, reqBody)
                .then((res: AxiosResponse) => res)
                .catch((err) => {
                    this.notificationContext.setNotification({ ...err, duration: 0 })
                }).finally(() => {
                    this.loaderContext.setLoader(false);
                })
        } else {
            return await postAPI(endpoints.updateRuleSetURL, reqBody)
                .then((res: AxiosResponse) => res)
                .catch((err) => {
                    this.notificationContext.setNotification({ ...err, duration: 0 })
                }).finally(() => {
                    this.loaderContext.setLoader(false);
                })
        }
    }

    getDataForRule = async (ruleId: Number) => {
        //dynamically pass ruleId on expandable click
        this.loaderContext.setLoader(true);
        const endpoint = endpoints.getDataForRuleURL + "/" + ruleId;
        return await getAPI(endpoint)
            .then(resp => {
                this.loaderContext.setLoader(false);
                return resp.data;
            }).catch(err => {
                this.loaderContext.setLoader(false);
                this.notificationContext.setNotification({ ...err })
            });
    }

    getSourceAndTargets = async (templateId: number) => {
        //dynamically pass templateId on expandable click
        const endpoint = endpoints.getSourceAndTargetURL + templateId + "/mappablefields";
        return await getAPI(endpoint)
            .then(resp => {
                return resp.data;
            }).catch(err => {
                this.notificationContext.setNotification({ ...err })
            });
    }

    //getDataForRule or this , oe of them needs to be cleaned up //todo
    getDataForRuleset = async (ruleId: number) => {
        //dynamically pass ruleId on expandable click
        this.loaderContext.setLoader(true);
        const endpoint = endpoints.getDataForRuleURL + "/" + ruleId;
        return await getAPI(endpoint)
            .then(resp => {
                this.loaderContext.setLoader(false);
                return resp.data;
            }).catch(err => {
                this.loaderContext.setLoader(false);
                this.notificationContext.setNotification({ ...err })
            });
    }

    deleteRule = async (ruleId: Number) => {
        //dynamically pass ruleId on expandable click
        const endpoint = endpoints.getDataForRuleURL + "/" + ruleId;
        return await deleteAPI(endpoint).then((resp: any) => {
            return resp.status;
        }).catch((err: any) => {
            this.notificationContext.setNotification({ ...err })
        });
    }

    getUserInfo = async (userId: any) => {
        this.loaderContext.setLoader(true);
        return await getAPI(endpoints.getUserInfoURL + userId)
            .then(res => {
                this.loaderContext.setLoader(false);
                return Promise.resolve(res.data);
            }).catch(err => {
                this.loaderContext.setLoader(false);
                this.notificationContext.setNotification({ ...err })
            });
    }
}


export const RuleSummaryService2 = {
    getDataForRuleset: async (id: number) => {
        const endpoint = endpoints.getDataForRuleURL + "/" + id;
        return await getAPI(endpoint)
    }
}