import './App.css';
import { useReducer, useState, useEffect } from "react";
import { AuthProvider, authReducer, useCompany, useSession } from "./auth";
import { SessionHandler } from './handlers/SessionHandler';
import { InteropHandlers } from './handlers/InteropHandlers';
import { NotificationContextProvider } from './components/utils/notificationContext';
import { CustomNotification } from './components/notification/customNotification';
import { ConfigProvider } from 'antd';
import * as FullStory from '@fullstory/browser';
import { IQMLayout, ThemeService } from '@iqm-ui-library/booster';
import { customThemeConfig } from './theme.config';
import { LoaderContextProvider } from './components/utils/loaderContext';
import { CustomLoader } from './components/loader/customLoader';
import { ErrorContextProvider } from './components/utils/errorContext';
import { SuccessContextProvider } from './components/utils/successContext';
import { RouterProvider } from 'react-router-dom';
import { CustomAlert } from './components/alert/customAlert';
import reconciliationRouter from './router';

import './home.scss'

function App() {
    const session: any = useSession();
    const company: any = useCompany();

    useEffect(() => {
        if (process.env.REACT_APP_FULLSTORY_ENABLED === 'true') {
            initFullStory('o-1A2CZG-na1', company?.parent?.company?.id, session?.session?.user?.id);
        }
    }, []);

    function initFullStory(orgId: string, parentEntityId: string, userId: string) {
        FullStory.init({ orgId: orgId },
            ({ sessionUrl }) => console.log(`Started session: ${sessionUrl}`));
        FullStory.identify(parentEntityId + ':' + userId, {});
    }

    const [authState, authDispatch] = useReducer(authReducer, {
        referrer: null,
        isLoggedIn: false,
    });

    const [selectedTheme, setSelectedTheme] = useState(ThemeService.changeTheme('CustomTheme', () => {
    }, customThemeConfig));

    return (
        <div className="App">
            <ConfigProvider theme={selectedTheme}>
                {/* <CordovaApp host={"https://cc-tiger.eng.dxpcloud.net"}/>*/}
                <NotificationContextProvider>
                    <AuthProvider value={{ state: authState, dispatch: authDispatch }}>
                        <SessionHandler permissionsRequired='managereconciliations,managereconciliationconfigurations'>
                            <InteropHandlers>
                                <LoaderContextProvider>
                                    <CustomLoader />
                                    <ErrorContextProvider>
                                        <SuccessContextProvider>
                                            <IQMLayout key={"IQM-HOME-LAYOUT"} className={'home'}>
                                                <IQMLayout style={{ marginTop: 64 }}>
                                                    <RouterProvider router={reconciliationRouter} />
                                                    <CustomAlert />
                                                </IQMLayout>
                                            </IQMLayout>
                                        </SuccessContextProvider>
                                    </ErrorContextProvider>
                                </LoaderContextProvider>
                            </InteropHandlers>
                        </SessionHandler>
                    </AuthProvider>
                    <CustomNotification />
                </NotificationContextProvider>
            </ConfigProvider>
        </div> 
    );
}

export default App;
