export interface IRuleSet {
    id?: number,
    activeRule?: number,
    name?: string,
    templateId?: number,
    version?: number,
    template?: ITemplateReq,
    rules?: IRule[]
}

export interface IRule {
    id?: number,
    name: string,
    conditions: IRuleCondition[],
    reasonCode?: string,
    cardinality?: boolean | string,
    order?: number,
    action?: string,
    actions?: IRuleAction[]
}

export interface IRuleCondition {
    operator?: string,
    sourceField?: IField
    sourceFieldId?: number,
    targetField?: IField
    targetFieldId?: number
    [DataSourceFieldEnum.retailerDataSource]?: string,
    [DataSourceFieldEnum.templateDataSource]?: string,
    [DataSourceFieldEnum.dataSourceValue]?: string | number | boolean,
    leftField?: IFieldOrigin,
    rightField?: IFieldOrigin,
    field?: IFieldOrigin,
}

interface IFieldOrigin {
    dataType?: string,
    id?: number,
    name?: string,
    origin?: string
}

interface IField {
    id: number,
    name: string,
    columnIdentifier: string,
    dataType: Number
}

export interface IRuleAction {
    actionType?: string;
    reasonCode?: string
}

export interface IRuleSetReq {
    id?: number
    name?: string,
    version?: number,
    template: ITemplateReq,
    rules?: IRuleReq[]
}

interface IRuleReq {
    id?: number,
    name: string,
    conditions: IRuleConditionReq[],
    order?: number,
    actions?: IRuleAction[]
}

interface IRuleConditionReq {
    operator?: string,
    sourceFieldId?: number,
    targetFieldId?: number
}

export interface ITemplateReq {
    id?: number,
    version?: number
}

export interface IRulesetFormProps {
    // navigatedFrom?: string,
    formData?: IRuleSet,
    // handleMenu: (event: { key: string }) => void,
}

export enum OperatorEnum {
    EqualTo = "EQUAL",
    EqualToValue = "EQUAL TO VALUE",
    NotEqualToValue = "NOT EQUAL TO VALUE",
  // adding explicit IS (NOT) EMPTY operators that take no value and will replace this code in future
  //  IsNotEmpty = "IS (NOT) EMPTY"
}

export enum SpecialOperatorEnum {
    GreaterThanValue = "GREATER THAN VALUE",
    LessThanValue = "LESS THAN VALUE",
    // GreaterThan = "GREATER THAN",
    // LessThan = "LESS THAN",
}

// Text, Name, PhoneNumber
export enum SpecialOperatorEnum2 {
    ContainsValue = "CONTAINS VALUE",
    DoesNotContainValue = "DOES NOT CONTAIN VALUE",
    StartsWithValue = "STARTS WITH VALUE",
    DoesNotStartWithValue = "DOES NOT START WITH VALUE",
    EndsWithValue = "ENDS WITH VALUE",
    DoesNotEndWithValue = "DOES NOT END WITH VALUE",
}

export enum ActionEnum {
    SetReasonCode = "Set reason code",
    Reconcile = "Reconcile",
}

export enum ActionKeysEnum {
    SetReasonCode = "SetReasonCode",
    Reconcile = "Reconcile",
}

export enum DataSourceEnum {
    RetailerData = "Retailer data",
    // TemplateName = "",
}

export enum DataSourceKeysEnum {
    RetailerDataKey = 'RetailerData',
    TemplateNameKey = "TemplateName"
}

export enum DataSourceFieldEnum {
    retailerDataSource = 'retailerData',
    templateDataSource = 'templateName',
    dataSourceValue = 'value'
}

export enum FieldDataSourceEnum {
    PhoneNumber = 'telephone',
    Text = 'text',
    Name = 'text',
    Number = 'number',
    Date = 'date',
    Currency = 'currency',
    Flag = 'flag'
}

export const DataTypeExceptionList = {
    Number: ['Date'],
    Currency: ['Date'],
    Date: ['Number', 'Currency', 'Flag'],
    Flag: ['Date'],
}
