import React, {useEffect, useContext } from "react";
import { Result, Spin } from "antd";
import styled from "styled-components";
import { AuthContext, Token, useLogin, useToken} from "../auth";
import { useHttp } from "../http";
import { useAsyncError } from "../utilities";
import * as Sentry from "@sentry/react";
import {IHandlerProps} from "./HandlerProps";
import axios, { AxiosError } from "axios";
import { redirectToLogin } from "../auth/utilities";
import { TokenByCodeEndpoint } from "../constants";

const SpinContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  align-items: center;
  justify-content: center;
`;

const COMPANY_ID = "companyId";
const storeCompanyId = (companyId: number) => {
  localStorage.setItem(COMPANY_ID, String(companyId));
  sessionStorage.setItem(COMPANY_ID, String(companyId));
};

const SessionHandler: React.FunctionComponent<IHandlerProps> = (props: any) => {
  const { state, dispatch } = useContext(AuthContext);
  const { httpClient } = useHttp();
  const { throwErrorOnRender } = useAsyncError();
  const { getToken, clearToken } = useToken();
  const { login } = useLogin();
  const TOKEN = "token";

  useEffect(() => {
    const attemptLogin = async () => {
      try {
        await getToken();
        dispatch({ type: "login" });
      } catch {
        await login();
      }
    };

    attemptLogin();

    const intervalId = setInterval(async () => {
      try {
        const serializedToken = localStorage.getItem(TOKEN);
        if (!serializedToken) {
          clearInterval(intervalId);
          redirectToLogin();
        }
        // Try to deserialize the token from localStorage
        let authToken = null;
        try{
            authToken = serializedToken ? (JSON.parse(serializedToken) as Token).value : null;
        } catch (error) {
        }
        if (!authToken) {
          clearInterval(intervalId);
          redirectToLogin();
        }
        if( authToken != null){
            const response = await axios.get(TokenByCodeEndpoint(authToken));
        }
      } catch (error) {
            console.error('Error validating token', error);
            if(error instanceof AxiosError && error?.message == 'Request failed with status code 401'){
                clearInterval(intervalId);
                clearToken();
                redirectToLogin();
            }
      }
    }, 20000); // Check every 10 seconds

    return () => clearInterval(intervalId); 

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getMe = async () => {
      try {
        const me = await httpClient.getMe();
        if(me instanceof AxiosError && me['message'] === 'Request failed with status code 401'){
          const sessionStorageItems = ['notification', 'reconciliationIds'];
          sessionStorageItems.forEach(item => sessionStorage.removeItem(item));
          const TOKEN = "token";
          const OAUTH_TOKEN = "oauthToken";
          localStorage.removeItem(TOKEN);
          localStorage.removeItem(OAUTH_TOKEN);
          sessionStorage.removeItem(OAUTH_TOKEN);
          redirectToLogin();
        }
        if(me instanceof AxiosError){
          me.Error = me['message'];
        }
        dispatch({ type: "set_me", me });

        Sentry.setUser({ id: me.Id.toString() });
      } catch (e) {
        //  throwErrorOnRender(e);        
      }
    };

    const getParentEntity = async () => {
      try {
        const parentEntity = await httpClient.getParentEntity();
        if(parentEntity instanceof AxiosError){
          parentEntity.Error = parentEntity['message'];
        }
        dispatch({ type: "set_parententity", parent:parentEntity });
        storeCompanyId(parentEntity.Id)

      } catch (e) {
        // throwErrorOnRender(e);
      }
    };

    if (state.isLoggedIn && !state.me) {
      getMe();
      getParentEntity();
    }
  }, [httpClient, state.me, state.isLoggedIn, dispatch, throwErrorOnRender]);

  if (!state.isLoggedIn || !state.me || !state.parentEntity) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  if(state.me?.Error || state.parentEntity?.Error){
    return (
      <Result
      status="error"
      title={ state.me.Error ? "ReconcilePro : " + state.me.Error : "ReconcilePro : " + state.parentEntity?.Error }
      subTitle="Please check network connection. If the connection is good, check for site outages. Please contact site admin if the issue persists."
      extra={<a href="">Refresh</a>}
      />
    );
  }

  return <>{props.children}</>;
};

export { SessionHandler };
